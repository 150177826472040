<template>
  <div class="text-sm">
    {{ message }}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Settings extends Vue {
  message = 'Authentication...'

  mounted (): void {
    this.$emitter.on('onAuthStateApproved', this.loadData)
  }

  destroyed (): void {
    this.$emitter.off('onAuthStateApproved', this.loadData)
  }

  async loadData (): Promise<void> {
    const zohoInvoiceAccessToken = this.$route.query.access_token
    const zohoInvoiceRefreshToken = this.$route.query.refresh_token

    const zohoInvoiceAccessTokenLifetime = Number(this.$route.query.expires_in)
    const zohoInvoiceAccessTokenExpires = (Date.now() / 1000) + zohoInvoiceAccessTokenLifetime

    if (zohoInvoiceAccessToken) {
      this.message = 'Try to save ZohoInvoice Auth-Tokens ...'

      const data = {
        zohoInvoiceAccessToken,
        zohoInvoiceAccessTokenExpires,
        zohoInvoiceRefreshToken: zohoInvoiceRefreshToken || null
      }

      await this.$store.dispatch('user/updateUserProfile', data)
        .then(() => {
          this.message = 'Done. Redirecting to settings page ...'
          this.$router.push('/settings')
        })
        .catch((e) => {
          this.message = 'An error happened during saving:' + e.message
          console.error('Cant change value:', e.message, e)
        })
    }
  }
}
</script>
